<template>
    <div>
        <!--begin::Head-->
        <div class="kt-login__head">
      <span class="kt-login__signup-label">{{
        $t("AUTH.GENERAL.NO_ACCOUNT")
      }}</span
      >&nbsp;&nbsp;
            <router-link
                    class="kt-link kt-login__signup-link"
                    :to="{ name: 'register' }"
            >
                {{ $t("AUTH.GENERAL.SIGNUP_BUTTON") }}
            </router-link>
        </div>
        <!--end::Head-->

        <!--begin::Body-->
        <div class="kt-login__body">
            <!--begin::Signin-->
            <div class="kt-login__form">
                <div class="kt-login__title">
                    <h3>Resetear clave</h3>
                </div>

                <!--begin::Form-->
                <b-form class="kt-form" @submit.stop.prevent="onSubmit">
                    <b-form-group
                            id="example-input-group-1"
                            label=""
                            label-for="example-input-1"
                    >
                        <b-form-input
                                id="example-input-1"
                                name="example-input-1"
                                v-model="$v.form.email.$model"
                                :state="validateState('email')"
                                aria-describedby="input-1-live-feedback"
                                placeholder="Correo electrónico"
                        ></b-form-input>

                        <b-form-invalid-feedback id="input-1-live-feedback">
                            La dirección email no es válida
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <!--begin::Action-->
                    <div class="kt-login__actions">
                        <b-button
                                v-on:click="$router.push('login')"
                                class="btn btn-secondary btn-elevate kt-login__btn-primary"
                        >
                            {{ $t("AUTH.GENERAL.BACK_BUTTON") }}
                        </b-button>

                        <b-button
                                type="submit"
                                id="kt_submit"
                                class="btn btn-primary btn-elevate kt-login__btn-primary kt-spinner--light kt-spinner--right"
                        >
                           Resetear
                        </b-button>
                    </div>
                    <!--end::Action-->
                </b-form>
                <!--end::Form-->
            </div>
            <!--end::Signin-->
        </div>
        <!--end::Body-->
    </div>
</template>

<style lang="scss" scoped>
    .kt-spinner.kt-spinner--right:before {
        right: 8px;
    }
</style>

<script>
    import {mapState} from "vuex";
    import {RESET} from "@/store/auth.module";
    import {validationMixin} from "vuelidate";
    import {email, required} from "vuelidate/lib/validators";
    import Swal from "sweetalert2";

    export default {
        mixins: [validationMixin],
        name: "reset",
        data() {
            return {
                // Remove this dummy login info
                form: {
                    email: "",
                }
            };
        },
        validations: {
            form: {
                email: {
                    required,
                    email
                }
            }
        },
        methods: {
            validateState(name) {
                const {$dirty, $error} = this.$v.form[name];
                return $dirty ? !$error : null;
            },
            resetForm() {
                this.form = {
                    email: null
                };

                this.$nextTick(() => {
                    this.$v.$reset();
                });
            },
            onSubmit() {
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    return;
                }

                const email = this.$v.form.email.$model;

                const submitButton = document.getElementById("kt_submit");
                submitButton.classList.add(

                );
                this.$store.dispatch(RESET, {email}).then((x) => {
                    Swal.fire({
                        title: "Clave reseteada",
                        text: x.message,
                        icon: "success",
                        preConfirm: () => {
                            this.$router.replace('login')

                        }
                    });

                });
            }
        },
        computed: {
            ...mapState({
                errors: state => state.auth.errors,
                loading: state => state.auth.loading
            }),
            backgroundImage() {
                return process.env.BASE_URL + "assets/media/bg/bg-4.jpg";
            },

            btnLoadingClass() {
                if (this.loading) {
                    return "kt-spinner kt-spinner--light kt-spinner--right"
                }
                return '';
            }

        }
    };
</script>
